import * as React from "react";
import { useContext } from "react";
import Header from "./components/Header";
import { Box } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { getTheme } from "@library/theme/createDefaultTheme";
import { SettingsContext } from "@library/settings/provider";
import { init } from "@library/scripts/sentry";

import Router from "./Router";
import { TenantName } from "@library/theme/multitenancy";

init();

function Theme() {
  const { channelTheme } = useContext(SettingsContext);

  return (
    <ThemeProvider theme={getTheme(channelTheme as TenantName)}>
      <CssBaseline /> {/* This normalizes styles across browsers */}
      <BrowserRouter>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            bgcolor: "rgb(239, 239, 239)",
          }}
        >
          <Header />
          <Router />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Theme;
