import React, { useCallback, useEffect, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  convertGoogleAddressToAddress,
  getGoogleAddressPart,
  PlacesAddress,
} from "@library/common/address";

import api from "@library/api";
import { getLanguage } from "@library/theme/multitenancy";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { operationalStates } from "@library/common";

export interface PlaceResult {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

const mapGooglePlacesResultToPlacesResult = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): PlacesAddress => {
  const address = {
    formatted_address: value.formatted_address,
    address_components: value.address_components,
    geometry: {
      location: {
        lat: value.geometry?.location?.lat(),
        lng: value.geometry?.location?.lng(),
      },
    },
  };

  return address;
};

declare global {
  interface Window {
    matchedAddress: boolean;
  }
}
window.matchedAddress = false;

export const AutocompleteAddress = (props: WidgetProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { payload, setPayload, setSlideDirection } = props.formContext || {};
  const [isLoading, setIsLoading] = useState(false);
  const { width } = props.uiSchema || {};
  const [error, setError] = useState("");

  const handleSubmit = useCallback(() => {
    const { address, formatted_address } = payload?.user ?? {};
    if (!address || address !== formatted_address) {
      setError("Please select an address from the dropdown");
      return;
    } else {
      setSlideDirection(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const submitHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        setTimeout(() => {
          handleSubmit();
        }, 1000);
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    window.addEventListener("keydown", submitHandler, false);
    return () => window.removeEventListener("keydown", submitHandler, false);
  }, [submitHandler]);

  useEffect(() => {
    if (window.matchedAddress === true) return;
    const qs = queryString.parse(location.search);
    const address = qs.address;
    if (address && import.meta.env.VITE_GOOGLE_MAPS_API_KEY) {
      setIsLoading(true);
      api
        .post("onboarding/address", {
          address,
        })
        .then(({ data = {} }) => {
          window.matchedAddress = true;
          const { state } = data;
          if (!operationalStates.includes(state)) {
            setIsLoading(false);
            localStorage.setItem("pearl-not-operational-address", data.address);
            navigate("/not-operational");
            return;
          }
          if (!getGoogleAddressPart(data, "street_number")) {
            setError("Address must include a street number.");
            setIsLoading(false);
            return;
          }
          props.onChange(data.address);
          setPayload({
            ...payload,
            user: {
              ...payload.user,
              ...data,
              formatted_address: data.address,
            },
          });
          setSlideDirection(1);
          setIsLoading(false);
          window.history.replaceState({}, document.title, "/onboarding");
        })
        .catch(() => {
          props.onChange(address);
          setPayload({
            ...payload,
            user: {
              ...payload.user,
              address,
            },
          });
          setSlideDirection(1);
          setIsLoading(false);
          window.history.replaceState({}, document.title, "/onboarding");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      setIsLoading(true);
      const address = mapGooglePlacesResultToPlacesResult(place);
      const userAddress = convertGoogleAddressToAddress(address);
      if (!getGoogleAddressPart(address, "street_number")) {
        setError("Address must include a street number.");
        setIsLoading(false);
        return;
      }
      props.onChange(address.formatted_address);
      try {
        await api.post("onboarding/address", {
          ...userAddress,
          fullAddress: address.formatted_address,
        });
      } catch (e) {
        console.error("Unable to save address");
      }
      const { state } = userAddress;
      if (!operationalStates.includes(state)) {
        setIsLoading(false);
        localStorage.setItem(
          "pearl-not-operational-address",
          address.formatted_address
        );
        navigate("/not-operational");
        return;
      }
      setPayload({
        ...payload,
        user: {
          ...payload.user,
          ...userAddress,
          address: address.formatted_address,
          formatted_address: address.formatted_address,
        },
      });
      setIsLoading(false);
      // setSlideDirection(1);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  const addessSearchButtonText =
    theme?.config?.language[getLanguage(theme)]?.addessSearchButtonText ?? "";

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {isLoading && (
        <Box
          justifyContent="center"
          alignItems="center"
          sx={{
            zIndex: 9999,
            position: "fixed",
            top: "64px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            background: `rgba(255,255,255,0.05)`,
          }}
        >
          <Stack
            sx={{
              top: "calc(50% - 20px)",
              left: "calc(50% - 20px)",
              position: "relative",
            }}
          >
            <CircularProgress color="secondary" />
          </Stack>
        </Box>
      )}
      <Stack
        sx={{ width: "100%" }}
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="row"
        >
          <TextField
            inputRef={materialRef}
            variant="filled"
            type="text"
            className="custom"
            placeholder={
              theme?.config?.language[getLanguage(theme)]
                ?.enterAddressPlaceholder ??
              "Enter your address to see your design and price in minutes"
            }
            defaultValue={props.value}
            required={props.required}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
            sx={{
              width,
              input: {
                padding: "20px",
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: 1,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                height: "20px",
                minHeight: "20px",
              },
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleSubmit();
            }}
            sx={{
              padding: ["17px", "19.5px"],
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              width: addessSearchButtonText ? "200px" : undefined,
              minWidth: addessSearchButtonText ? "200px" : undefined,
              height: "60px",
              minHeight: "60px",
            }}
          >
            {addessSearchButtonText ? (
              <Typography sx={{ color: theme.palette.primary.contrastText }}>
                {addessSearchButtonText}
              </Typography>
            ) : (
              <SearchIcon />
            )}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AutocompleteAddress;
