import { Room } from "./home";

export const initialOnboardingPayload = {
  homeType: "singleFamily",
  basement: "yes",
  attic: "yes",
  garage: "no",
  sqFootage: undefined,
  yearBuilt: undefined,
  ceilingHeight: 8,
  numBedrooms: 1,
  numBathrooms: 1,
  aboveGroundStories: 1,
  upgrades: [],
  issues: [],
  homeQuality: [],
  homeQualityMoreInfo: "",
  rooms: [
    {
      type: "BATHROOM",
      name: "Bathroom",
      isCurrentlyHeated: true,
      isHeatComfortIssue: false,
      isCurrentlyAirConditioned: true,
      isAirConditionComfortIssue: false,
    },
    {
      type: "BEDROOM",
      name: "Bedroom",
      isCurrentlyHeated: true,
      isHeatComfortIssue: false,
      isCurrentlyAirConditioned: true,
      isAirConditionComfortIssue: false,
    },
    {
      type: "KITCHEN",
      name: "Kitchen",
      isCurrentlyHeated: true,
      isHeatComfortIssue: false,
      isCurrentlyAirConditioned: true,
      isAirConditionComfortIssue: false,
    },
    {
      type: "LIVING_ROOM",
      name: "Living Room",
      isCurrentlyHeated: true,
      isHeatComfortIssue: false,
      isCurrentlyAirConditioned: true,
      isAirConditionComfortIssue: false,
    },
  ],
  heatingEquipmentType: [],
  heatingEquipmentTypeMoreInfo: "",
  coolingEquipmentType: [],
  coolingEquipmentTypeMoreInfo: "",
  draftyStuffy: "",
  exteriorWalls: "",
  fuelSource: "",
  floorSize: "second_floor_is_same_size",
  interiorWalls: "",
  temperatureSummer: 72,
  temperatureWinter: 68,
  upgradesMoreInfo: "",
  windowType: "",
  whatsImportantToYou: [],
  whatsImportantToYouInfo: "",
  peopleLiveInHome: 1,
  houseHoldIncome: "",
  user: {
    supabaseId: "",
    firstName: "",
    lastName: "",
    email: "",
    formatted_address: "",
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    fipsCountyCode: "",
    communicationPreference: "",
    phoneNumber: "",
    lat: undefined,
    lng: undefined,
    channelId: "pearl",
  },
};

export interface OnboardingUser {
  supabaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  formatted_address: string;
  address: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  fipsCountyCode: string;
  communicationPreference: string;
  phoneNumber: string;
  lat: number;
  lng: number;
  channelId: string;
}

export interface OnboardingPayload {
  heatingEquipmentType: string[];
  heatingEquipmentTypeMoreInfo: string;
  coolingEquipmentType: string[];
  coolingEquipmentTypeMoreInfo: string;
  atticFinish: string;
  atticType: string;
  basementFinished: string;
  basementType: string;
  draftyStuffy: string;
  exteriorWalls: string;
  fuelSource: string;
  floorSize: string;
  garageType: string;
  houseHoldIncome: string;
  interiorWalls: string;
  temperatureSummer: number;
  temperatureWinter: number;
  upgradesMoreInfo: string;
  windowType: string;
  homeType: string;
  basement: string;
  attic: string;
  garage: string;
  sqFootage: number;
  yearBuilt: number;
  ceilingHeight: number;
  numBedrooms: number;
  numBathrooms: number;
  aboveGroundStories: number;
  user: Partial<OnboardingUser>;
  rooms: Partial<Room>[];
  upgrades: string[];
  issues: string[];
  homeQuality: string[];
  homeQualityMoreInfo: string;
  whyAreYouShoppingToday: string;
  whatsImportantToYou: string[];
  ownOrRent: string;
  whatsImportantToYouInfo: string;
  peopleLiveInHome: number;
  communicationPreference: string;
  phoneNumber: string;
}

export interface Onboarding {
  id: string;
  jobId: string;
  data?: OnboardingPayload;
  stepJSON?: string;
  dataJSON?: string;
  createdAt: Date;
  updatedAt: Date;
}

// z.object({
//   whyAreYouShoppingToday: z.string().default(""),
//   whatsImportantToYou: z.array(z.enum(WHATS_IMPORTANT_TO_YOU_TYPES)),
//   whatsImportantToYouInfo: z.string().default(""),
//   ownOrRent: z.string().default("own"),
//   homeType: z.enum(HOME_TYPES),
//   homeStyle: z.enum(HOME_STYLE_TYPES).default(""),
//   houseHoldIncome: z.string().default("More than"),
//   peopleLiveInHome: z.coerce.number().default(1),
//   homeQuality: z.array(z.enum(HOME_QUALITY_TYPES)),
//   homeQualityMoreInfo: z.string().optional(),
//   draftyStuffy: z.enum(DRAFTY_STUFFY_TYPES).default("").optional(),
//   draftyStuffyMoreInfo: z.string().optional(),
//   rooms: z.array(RoomSchema),
//   yearBuilt: z.number(),
//   sqFootage: z.number(),
//   aboveGroundStories: z.coerce.number(),
//   numberOfBedrooms: z.coerce.number(),
//   ceilingHeight: z.coerce.number(),
//   basement: z.string(),
//   atticType: z.string().default("no_attic"),
//   garage: z.string().default(""),
//   garageType: z.string().default("fully_detached"),
//   floorSize: z.string().default("second_floor_is_same_size"),
//   basementType: z.string().default("below_grade"),
//   basementFinished: z.string().default("unfinished"),
//   atticFinish: z.string().default("unfinished"),
//   exteriorWalls: z.string(),
//   interiorWalls: z.string(),
//   windowType: z.string(),
//   finishedBasement: z.string(),
//   attic: z.string(),
//   heatingEquipmentType: z.array(z.enum(HEATING_EQUIPMENT_TYPES)),
//   coolingEquipmentType: z.array(z.enum(COOLING_EQUIPMENT_TYPES)),
//   temperatureSummer: z.number().nullable(),
//   temperatureWinter: z.number().nullable(),
//   fuelSource: z.enum(FUEL_SOURCE_TYPES),
//   issues: z.array(z.enum(ISSUE_TYPES)).default([]),
//   upgrades: z.array(z.enum(UPGRADE_TYPES)).default([]),
//   user: z.object({
//     supabaseId: z.string().default(""),
//     firstName: z.string().default(""),
//     lastName: z.string().default(""),
//     email: z.string().default(""),
//     address1: z.string(),
//     address2: z.string().default(""),
//     city: z.string(),
//     state: z.string(),
//     zip: z.string(),
//     county: z.string().default(""),
//     communicationPreference: z.string().default(""),
//     phoneNumber: z.string().default(""),
//     lat: z.number().optional(),
//     lng: z.number().optional(),
//   }),
// });
