import React, { useCallback, useContext, useState } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";

import { QuoteUserInput } from "@library/domain/quote";

import { api } from "@library/api";
import { QuoteContext } from "./";
import { EstimateContext } from "./EstimateDetail";

interface LabelProps {
  title?: string;
  description?: string;
  input?: unknown;
  href?: string;
}

const Label = ({ title, description, href, input }: LabelProps) => {
  const theme = useTheme();
  return (
    <Stack p={2} pl={0} pt={1.4}>
      <Typography
        variant="body1"
        fontSize="0.9rem"
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        {title}
      </Typography>
      <Typography fontSize="0.8rem">
        {description}
        {input ? <>{input}</> : null}
        {href ? (
          <Link
            href={href}
            color="secondary"
            ml={1}
            sx={{ fontSize: "0.9rem" }}
          >
            Learn More
          </Link>
        ) : null}
      </Typography>
    </Stack>
  );
};

const AdditionalCustomization = ({
  setWarning,
}: {
  setWarning: (_value: string) => void;
}) => {
  const theme = useTheme();
  const [waterHeaterState, setWaterHeaterState] = useState(false);
  const [airQualityState, setAirQualityState] = useState(false);
  const [insulationState, setInsulationState] = useState(false);
  const [moreInfoState, setMoreInfoState] = useState("");

  const { estimate } = useContext(EstimateContext);

  const { quote, quoteId } = useContext(QuoteContext);

  const userInput = (quote?.userInput as QuoteUserInput) ?? {};

  const {
    waterHeater = waterHeaterState,
    airQuality = airQualityState,
    insulation = insulationState,
    moreInfo = moreInfoState,
  } = userInput;

  const updateQuoteStatusHandler = useCallback(
    (key: string, value: string | boolean) => {
      api
        .put("quote", { userInput: { [key]: value } }, { id: quoteId })
        .catch(() => {
          setWarning(
            "A backend error occured while carrying out your last request.",
          );
        });
    },
    [quoteId, setWarning],
  );

  const handleMoreInfo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ?? "";
    setMoreInfoState(value);
    await updateQuoteStatusHandler("moreInfo", value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUserInput = useCallback(debounce(handleMoreInfo, 2000), [
    quoteId,
    updateQuoteStatusHandler,
  ]);

  if (!estimate) return null;

  const moreInfoChecked = moreInfo?.length ? true : false;

  return (
    <Stack
      mt={2}
      p={1}
      px={2}
      spacing={0}
      sx={{
        borderRadius: "25px",
        border: "1px solid",
        borderColor: "action.active",
      }}
    >
      <Typography
        variant="body1"
        py={1}
        fontSize={"0.9rem"}
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
      >
        Additional Customization Available:
      </Typography>
      <Divider />

      <Stack direction="column">
        <Stack direction="row" alignItems="flex-start">
          <Stack direction="column" alignItems="flex-start">
            <Stack flexGrow={1} spacing={2} alignItems="flex-start">
              <FormGroup color="secondary">
                <FormControlLabel
                  sx={{
                    "input[type=checkbox], svg": {
                      borderColor: theme.palette.secondary.main,
                      fill: theme.palette.secondary.main,
                    },
                    alignItems: "flex-start",
                  }}
                  control={
                    <Checkbox
                      onChange={(
                        _event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => {
                        setWaterHeaterState(checked);
                        void updateQuoteStatusHandler("waterHeater", checked);
                      }}
                      defaultChecked={waterHeater}
                      color="secondary"
                    />
                  }
                  label={
                    <Label
                      title="Water Heater"
                      description="Replacing your water heater could result in additional savings."
                      // href="/learn-more"
                    />
                  }
                />
              </FormGroup>
            </Stack>
            <Stack flexGrow={1} spacing={2} alignItems="flex-start">
              <FormGroup color="secondary">
                <FormControlLabel
                  sx={{
                    "input[type=checkbox], svg": {
                      borderColor: theme.palette.secondary.main,
                      fill: theme.palette.secondary.main,
                    },
                    alignItems: "flex-start",
                  }}
                  control={
                    <Checkbox
                      onChange={(
                        _event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => {
                        setAirQualityState(checked);
                        void updateQuoteStatusHandler("airQuality", checked);
                      }}
                      defaultChecked={airQuality}
                      color="secondary"
                    />
                  }
                  label={
                    <Label
                      title="Air Quality"
                      description="Consider investing in air purification, filtration, and humidification."
                      // href="/learn-more"
                    />
                  }
                />
              </FormGroup>
            </Stack>
          </Stack>
          <Stack direction="column">
            <Stack direction="row" flexGrow={1} spacing={2}>
              <FormGroup color="secondary">
                <FormControlLabel
                  sx={{
                    "input[type=checkbox], svg": {
                      borderColor: theme.palette.secondary.main,
                      fill: theme.palette.secondary.main,
                    },
                    alignItems: "flex-start",
                  }}
                  control={
                    <Checkbox
                      onChange={(
                        _event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean,
                      ) => {
                        setInsulationState(checked);
                        void updateQuoteStatusHandler("insulation", checked);
                      }}
                      defaultChecked={insulation}
                      color="secondary"
                    />
                  }
                  label={
                    <Label
                      title="Insulation"
                      description="Adding insulation means better comfort and additional savings."
                      // href="/learn-more"
                    />
                  }
                />
              </FormGroup>
            </Stack>
            <Stack flexGrow={1} spacing={2}>
              <FormGroup color="secondary">
                <FormControlLabel
                  sx={{
                    "input[type=checkbox], svg": {
                      borderColor: theme.palette.secondary.main,
                      fill: theme.palette.secondary.main,
                    },
                    alignItems: "flex-start",
                  }}
                  control={
                    <Checkbox
                      color="secondary"
                      defaultChecked={moreInfoChecked}
                    />
                  }
                  label={
                    <Label
                      input={
                        <TextField
                          sx={{
                            border: 0,
                            padding: "0px",
                            top: "0.3rem",
                            "div, textarea, fieldset": {
                              border: "0px",
                              padding: "0px",
                            },
                          }}
                          multiline
                          maxRows={5}
                          minRows={5}
                          inputProps={{
                            style: {
                              padding: "0px",
                              border: 0,
                              fontSize: "0.8rem",
                              lineHeight: "0.8rem",
                            },
                          }}
                          defaultValue={moreInfo}
                          onChange={throttledUserInput}
                          placeholder="Tell us more..."
                        />
                      }
                    />
                  }
                />
              </FormGroup>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AdditionalCustomization;
