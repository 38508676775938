"use client";

import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";

import useSwitch from "@library/hooks/switch";
import WaitingFixedTime from "@library/components/WaitingFixedTime";
import Clock from "@library/components/icons/Clock";

export const WaitingForEstimates = () => {
  const circularProgress = useSwitch();
  const theme = useTheme();

  const handleSwitchToCircular = () => circularProgress.turnOn();

  return (
    <Stack spacing={5} alignItems="center">
      {circularProgress.on ? (
        <Clock color={theme.palette.primary.main} />
      ) : (
        <WaitingFixedTime
          variant="linear"
          waitTimeInSeconds={90}
          steps={90}
          onDone={handleSwitchToCircular}
        />
      )}
      <Typography
        variant="h4"
        textAlign="center"
        color={theme.palette.primary.main}
        style={{
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Creating your instant quote
      </Typography>
      <Typography
        variant="h4"
        textAlign="center"
        color={theme.palette.primary.main}
        style={{
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: "1.0rem",
        }}
      >
        (This can take up to 2 minutes)
      </Typography>
    </Stack>
  );
};

export default WaitingForEstimates;
