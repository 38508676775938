import { Theme } from "@mui/material";

// Primary Colors
const DEEP_PURPLE = "#591E90";
const MEDIUM_ORCHID = "#9B48B5";

// Secondary Colors
const BRIGHT_BLUE = "#0088D1";
const SUNFLOWER = "#FFB404";
const RED = "#E64A19";
const LIGHT_RED = "#FBE8E6";
const COCONUT_TWIST = "#FFF9E1";

// Common
const WHITE = "#FFF";
const BLACK = "#000";

// Text
const GRAY = "#65635A";

export const baseConfig = {
  name: "Pearl",
  showBrands: true,
  tiers: {
    default: "base",
    recommended: "base",
    available: ["replace", "base", "pearl"],
  },
  language: {
    "en-us": {
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        homeType: "Home Type",
        yearBuilt: "Year Built",
        sqFootage: "Sq Footage",
        aboveGroundStories: "Above Ground Stories",
        numBedrooms: "Number of Bedrooms",
        ceilingHeight: "Ceiling Height",
        basement: "Basement",
        attic: "Attic",
        garage: "Garage",
        floorSize: "Floor Size",
        "floorSize.enum": {
          second_floor_is_same_size:
            "2nd floor is the same size as the 1st floor.",
          second_floor_a_bit_smaller:
            "2nd floor is a bit smaller than the 1st floor.",
          second_floor_a_lot_smaller:
            "2nd floor is a lot smaller than the 1st floor.",
        },
        basementType: "Basement Type",
        "basementType.enum": {
          below_grade: 'Fully "below grade" and underground.',
          partially_below_grade: 'Partially "below grade" - garden level.',
          above_grade: 'Fully "above grade" - a walkout.',
        },
        basementFinished: "Basement Finish",
        "basementFinished.enum": {
          finished: "Fully finished",
          unfinished: "Completely unfinished",
          partially_finished: "Partially finished",
        },
        atticType: "Attic Type",
        "atticType.enum": {
          permanent_stairs: 'A "room" accessible by a permanent staircase.',
          hatch: 'A "scuttle attic" accessible through a ceiling hatch.',
          no_attic: "No attic (or not accessible).",
        },
        atticFinish: "Attic Finish",
        "atticFinish.enum": {
          finished: "Fully finished",
          unfinished: "Completely unfinished",
          partially_finished: "Partially finished",
        },
        garageType: "Garage Type",
        "garageType.enum": {
          fully_detached: "Fully detached from the house.",
          attached_with_no_living_space_above:
            "Attached, but no living space above it.",
          attached_with_living_space_above:
            "Attached, with living space above it.",
        },
        "rooms.type": "Room type?",
        "rooms.type.enum": {
          KITCHEN: "Kitchen",
          DINING_ROOM: "Dining Room",
          LIVING_ROOM: "Living Room",
          MASTER_BEDROOM: "Bedroom",
          BEDROOM: "Bedroom",
          MASTER_BATHROOM: "Bathroom",
          BATHROOM: "Bathroom",
          REC_ROOM: "Recreation Room",
          MEDIA_ROOM: "Media Room",
          OFFICE: "Office",
          DEN: "Den",
          FOYER: "Foyer",
          LAUNDRY_ROOM: "Laundry Room",
        },
        "rooms.name": "Room name?",
        "rooms.floor": "Which floor?",
        "rooms.floor.enum": {
          basement: "Basement",
          "1": "1st floor",
          "2": "2nd floor",
          "3": "3rd floor",
          attic: "Attic",
        },
        "rooms.size": "What size?",
        "rooms.size.enum": {
          SMALL: "Small",
          MEDIUM: "Medium",
          LARGE: "Large",
          XL: "XL",
          XXL: "XXL",
        },
        "rooms.walls": "How many walls have windows?",
        "rooms.wallsWithWindows": "How many windows?",
        "rooms.wallsWithWindows.enum": {
          none: "None",
          "just one": "Just one.",
          "a few": "A few.",
          "a lot": "A lot.",
          "huge ones": "Huge ones!",
        },
        heatingEquipmentType: "Heating Equipment",
        heatingEquipmentTypeMoreInfo:
          "Anything else we should know? (Optional)",
        "heatingEquipmentType.enum": {
          furnace_with_ducts: "Furnace with ductwork",
          boiler_with_radiators: "Boiler with radiators",
          electric_resistive_with_baseboards:
            "Electric resistive heat with baseboards",
          heat_pump_mini_split: "Heat pump, also known as a “mini split”",
          none_of_these: "None of these match my heating system",
        },
        fuelSource: "Fuel Source",
        "fuelSource.enum": {
          natural_gas: "Natural gas",
          fuel_oil: "Fuel oil",
          electricity: "Electricity",
          propane: "Propane",
          something_else: "Another fuel source",
          i_don_t_know: "I don't know",
        },
        "rooms.isCurrentlyHeated": "Currently heat?",
        "rooms.isHeatComfortIssue": "Comfort issues?",
        "rooms.heatComfortIssueInfo": "Please explain. (Optional)",
        coolingEquipmentType: "Cooling Equipment",
        coolingEquipmentTypeMoreInfo:
          "Anything else we should know? (Optional)",
        "coolingEquipmentType.enum": {
          central_air_conditioning: "Central air conditioning",
          window_unit_air_conditioning: "Window-unit air conditioning",
          evaporative_cooling: "Evaporative cooling",
          heat_pump_mini_split: 'Heat pump, also known as a "Mini Split"',
          something_else: "Another system",
          no_air_conditioning: "No air conditioning",
        },
        "rooms.isCurrentlyAirConditioned": "Has A/C now?",
        "rooms.isAirConditionComfortIssue": "Comfort Issues?",
        "rooms.airConditionComfortIssueInfo": "Please explain. (Optional)",
        "materials.exteriorWalls": "Exterior Walls",
        "materials.interiorWalls": "Interior Walls",
        exteriorWalls: "Exterior Walls",
        "exteriorWalls.enum": {
          stucco: "Stucco",
          vinyl: "Vinyl",
          fiber_cement: "Fiber Cement",
          brick: "Brick",
          wood: "Wood",
          stone: "Stone",
          metal: "Metal",
        },
        interiorWalls: "Interior Walls",
        "interiorWalls.enum": {
          stucco: "Stucco",
          drywall: "Drywall",
          plaster: "Plaster",
          brick: "Brick",
          wood_panel: "Wood Panel",
          concrete: "Concrete",
          ceramic_tile: "Ceramic Tile",
        },
        "windowType.enum": {
          single: "Single-pane windows",
          double: "Double-pane windows",
          triple: "Triple-pane windows",
          im_not_sure: "I'm not sure",
        },
        upgrades: "Upgrades",
        "upgrades.enum": {
          added_insulation: "Added insulation",
          upgraded_windows: "Upgraded windows",
          air_sealing: "Air sealing",
          upgraded_electrical_panel: "Upgraded electrical panel",
          duct_insulation_sealing: "Duct insulation / sealing",
          something_else: "Something else",
        },
        upgradesMoreInfo: "Anything else we should know? (Optional)",
        issues: "Issues",
        "issues.enum": {
          roof_damage: "Roof damage",
          asbestos: "Asbestos",
          wall_damage: "Wall damage",
          visible_mold: "Visible mold",
          missing_windows: "Missing windows",
          standing_water: "Standing water",
          electrical_issues: "Electrical issues",
          indoor_air_quality: "Indoor air quality",
        },
        issuesMoreInfo: "Anything else we should know? (Optional)",
        draftyStuffy: "Drafty / Stuffy",
        "draftyStuffy.enum": {
          yes_drafty:
            "Yes, there can be cool drafts even when windows are shut.",
          yes_stuffy:
            "Yes, sometimes the air quality can feel stuffy or uncomfortable.",
          no: "All good!",
        },
        draftyStuffyMoreInfo: "Anything else we should know? (Optional)",
        homeQuality: "Home Quality",
        "homeQuality.enum": {
          yes: "Yes!",
          no_always_cold: "No, too cold in the winter.",
          no_always_hot: "No, too hot in the summer.",
          uneven: "No, some rooms are too hot or too cold.",
        },
        homeQualityMoreInfo: "Anything else we should know? (Optional)",
        whyAreYouShoppingToday: "Why Are You Shopping",
        "whyAreYouShoppingToday.enum": {
          equipmentBroken: "My equipment is broken.",
          equipmentLastLeg: "My equipment is old, I want to replace it.",
          moreEfficientSystem: "I want a more efficient system.",
          justBrowsing: "I'm just browsing.",
        },
        whatsImportantToYou: "What's Important To You",
        "whatsImportantToYou.enum": {
          lowUpFrontCost: "Low up-front cost",
          lowerMonthlyBills: "Reduce energy costs",
          climateImpact: "Climate impact",
          betterComfort: "Comfort",
          somethingElse: "Other",
        },
        whatsImportantToYouInfo: "Anything else we should know? (Optional)",
        ownOrRent: "Own or Rent",
        "ownOrRent.enum": {
          own: "Own",
          rent: "Rent",
        },
        peopleLiveInHome: "People Live In Home",
        communicationPreference: "Communication Preferences",
        "communicationPreference.enum": {
          email: "Email",
          text: "Text",
          call: "Call",
        },
      },
      step: {
        AddressStep: {
          title:
            "Upgrade to efficient, climate-friendly, wallet-friendly home heating and cooling.",
          description: "",
        },
        OnboardingStep: { title: "", description: "" },
        GettingStartedStep: {
          title: "Let’s get started.",
          description:
            "We’ll help you design the right efficient heating and air conditioning system for your unique home and goals. It only takes 10 minutes.",
          step1Title: "Get your instant design and estimate",
          step1Description:
            "Tell us about your home and goals, then find your rebates. We’ll design the right system for your unique home and goals.",
          step1Time: "~10 min",
          step2Title: "Meet your advisor",
          step2Description:
            "We’ll confirm final details, answer your questions, and finalize your design.",
          step2Time: "within 1 day",
          step3Title: "Electrify your home",
          step3Description:
            "When you’re ready, we’ll schedule your installation with one of our licensed, vetted, experienced contractor partners.",
          step3Time: "within 2 weeks",
        },
        HomeConfirmationStep: {
          title: "Is this your home?",
          description: "",
        },
        FullNameStep: {
          title: "Tell us about yourself.",
          description: "We'll send you a link to view your estimate.",
        },
        HomeProfileStep: {
          title: "Please confirm details about your home.",
          description: "",
        },
        FloorSizeStep: {
          title: "How large is each floor?",
          description: "",
        },
        BasementTypeStep: {
          title: "Which basement is most like yours?",
          description: "",
        },
        BasementFinishedStep: {
          title: "Is your basement finished?",
          description: "",
        },
        AtticTypeStep: {
          title: "Which attic is most like yours?",
          description: "",
        },
        AtticFinishedStep: {
          title: "Is your attic finished?",
          description: "",
        },
        GarageStep: {
          title: "Which garage is most like yours?",
          description: "",
        },
        FloorPlanStep: {
          title: "List all of the rooms in your home",
          description: "",
        },
        RoomsConfigurationStep: {
          title: "Which rooms do you need to heat and cool?",
          description: "",
        },
        HeatingEquipmentStep: {
          title: "What kind of heating equipment do you currently have?",
          description: "",
        },
        FuelSourceStep: {
          title: 'What is your "fuel source" for heating?',
          description: "",
        },
        HeatRoomsStep: {
          title:
            "Which rooms do you heat today? Do you experience any winter comfort issues?",
          description: "",
        },
        AirConditioningStep: {
          title:
            "What kind of air conditioning equipment do you currently have?",
          description: "",
        },
        AirConditioningRoomsStep: {
          title:
            "Which rooms have air conditioning today? Do you experience any summer comfort issues?",
          description: "",
        },
        MaterialsSteps: {
          title: "What material are (most of) your walls made of?",
          description: "",
        },
        WindowsStep: {
          title: 'What "type" are (most of) the windows in your home?',
          description: "",
        },
        UpgradeStep: {
          title:
            "Are you aware of any upgrades to the home, since it was built?",
          description: "",
        },
        IssuesStep: {
          title: "Are you aware of any of these (common) issues at your home?",
          description: "",
        },
        TemperatureStep: {
          title: "What temperature do you usually set the thermostat at?",
          description: "",
          whyAreWeAsking:
            "We use this to estimate your energy consumption and the impact of your new system on your bills.",
        },
        DraftyStuffyStep: {
          title: 'Does your home ever feel "drafty" or "stuffy"?',
          description: "",
        },
        HomeQualityStep: {
          title: "Is the temperature of your home usually comfortable?",
          description: "",
        },
        WhyAreYouShoppingStep: {
          title: "Why are you shopping today?",
          description: "",
        },
        WhatsImportantToYouStep: {
          title: "What's important to you?",
          description: "",
        },
        AlmostDone: {
          title: "Almost done.",
          description: "Let‘s find your rebates.",
        },
        OwnOrRentStep: {
          title: "Do you own or rent your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        PeopleLiveInHomeStep: {
          title: "How many people live in your home?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        HouseHoldIncomeStep: {
          title: "What is your annual household income?",
          description:
            "This information helps determine if you are eligible for rebates and incentives, which we will apply to ensure that you get the best price.",
        },
        CommunicationPreferencesStep: {
          title: "Communication Preference",
          description: "",
        },
        FinalStep: {
          title: "Are you ready to see your quote?",
          description: 'Pressing "submit" will generate your estimate.',
        },
        GetEstimates: { title: "Creating your estimates!", description: "" },
      },
    },
  },
};

export const baseOverrides = {
  logo: {
    imageSrc: "",
    alt: "",
    width: 100,
    height: 40,
    poweredBy: false,
  },
  typography: {
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "45px",
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
      color: GRAY,
    },
  },
  palette: {
    common: {
      white: WHITE,
      black: BLACK,
    },
    text: {
      primary: BLACK,
      secondary: GRAY,
    },
    primary: {
      main: DEEP_PURPLE,
      light: MEDIUM_ORCHID,
    },
    secondary: {
      main: BRIGHT_BLUE,
    },
    background: {
      default: "#fff",
    },
    error: {
      main: RED,
      light: LIGHT_RED,
    },
    warning: {
      main: SUNFLOWER,
      light: COCONUT_TWIST,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "10px",
          fontWeight: 500,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: "auto",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "none",
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          borderRadius: "8px",
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 28,
        padding: 10,
        fontWeight: 500,
      },
    },
    defaultProps: {
      // LinkComponent: LinkBehaviour,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      filled: {
        backgroundColor: "#FFF",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      filled: {
        backgroundColor: "white",

        input: {
          padding: "25px 10px 10px",
          backgroundColor: "#FFF",
        },
      },
    },
  },
} as unknown as Theme;
