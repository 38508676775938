import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import find from "lodash/find";
import {
  Alert,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Modal,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "@library/custom.d.ts";

import { SettingsContext } from "@library/settings/provider";
import { Quote } from "@library/domain/quote";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import api from "@library/api";

import WaitingForEstimates from "@library/components/Concierge/WaitingForEstimates";
import CalendlyModal from "@library/components/Concierge/CalendlyModal";
import PearlLogoDark from "@library/assets/pearl_logo_dark.png";
import { ConciergeView } from "@library/components/Concierge";
import {
  EMAIL,
  getMobileOperatingSystem,
  PHONE_NUMBER_JAKE,
  PHONE_NUMBER_TEL,
} from "@library/common";
import { TenantName } from "@library/theme/multitenancy";
import { OnboardingPayload } from "@library/domain/onboarding";

const RebateModal = ({ quotes }: { quotes: Quote[] }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [rebates, setRebates] = useState({
    "Eligible Rebate": 0,
    "Potential Additional Rebate": 0,
  });

  useEffect(() => {
    const _r = {
      "Eligible Rebate": 0,
      "Potential Additional Rebate": 0,
    };
    quotes.forEach((quote) => {
      if (quote?.channelId !== "a2zero") return;
      quote.Estimate?.forEach((estimate) => {
        if (estimate.data?.muniPotentialRebate) {
          _r["Eligible Rebate"] = estimate.data?.muniRebate ?? 0;
          _r["Potential Additional Rebate"] =
            estimate.data?.muniPotentialRebate ?? 0;
        }
      });
    });
    if (_r["Eligible Rebate"] > 0) {
      setRebates(_r);
      setOpen(true);
    }
  }, [quotes]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: "32px",
          borderRadius: "8px",
          maxWidth: "600px",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Grid container sx={{ width: "100%" }} spacing={3}>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
              paddingRight: theme.spacing(2),
            }}
          >
            <img src={theme.logo.imageSrc} height="20" />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "left",
              paddingLeft: theme.spacing(2),
            }}
          >
            <img src={PearlLogoDark} height="20" />
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Eligible Rebates
            </Typography>
            <Typography align="center" variant="h4">
              {rebates["Eligible Rebate"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Potential Rebates
            </Typography>
            <Typography align="center" variant="h4">
              {rebates["Potential Additional Rebate"].toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="center" variant="subtitle1">
              Maximum Rebate
            </Typography>
            <Typography align="center" variant="h4">
              {(
                rebates["Eligible Rebate"] +
                rebates["Potential Additional Rebate"]
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body2">
              Be sure to schedule your home assessment to reserve your rebate.
              <br />
              <b>
                Your {theme.config.name} rebate is not reserved until you
                complete this step!
              </b>
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: theme.spacing(3),
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            View My Estimate
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const QuoteContext = createContext<{
  quoteId: string;
  quote: Partial<Quote>;
}>({
  quoteId: "",
  quote: {},
});

type Timer = ReturnType<typeof setTimeout>;

const ConciergePageView = ({
  zoom = "1.0",
  borderRadius = "0px",
  filter = undefined,
}: {
  zoom?: string;
  borderRadius?: string;
  filter?: string | undefined;
  backgroundColor?: string | undefined;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [intervalId, setIntervalId] = useState<Timer | null>(null);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [error, setError] = useState<string | ReactElement>("");
  const [warning, setWarning] = useState("");
  const [showCalendly, setShowCalendly] = useState(false);
  const [waitingForEstimates, setWaitingForEstimates] = useState(false);
  const [hasEstimate, setHasEstimate] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(SettingsContext);
  const [selectedTier, setSelectedTierValue] = useState(
    theme.config.tiers.default
  );
  const { quoteId } = useParams();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { setChannel } = useContext(SettingsContext);

  const quote = useMemo(() => {
    return find(quotes, { id: quoteId }) || ({} as Quote);
  }, [quoteId, quotes]);

  const setSelectedEstimate = useCallback(
    (tier: string, estimateId?: string) => {
      setSelectedTierValue(tier);
      if (estimateId) {
        api
          .put(
            "quote",
            { userInput: { selectedTier: tier, estimateId } },
            { id: quote.id }
          )
          .catch(() => {
            setWarning(
              "A backend error occured while carrying out your last request."
            );
            setIsLoading(false);
          });
      }
    },
    [quote]
  );

  useEffect(() => {
    if (!quote?.id) return;
    if (quote?.userInput?.selectedTier) {
      setSelectedEstimate(quote.userInput.selectedTier);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  useEffect(() => {
    if (!user.email) return;
    if (quoteId) return;
    if (hasFetched) return;
    clearInterval(intervalId as Timer);
    setHasFetched(true);
    setIsLoading(true);
    api
      .get("quote", { models: "Estimate" })
      .then((response) => {
        const { quotes = [] } = response;
        setQuotes(quotes);
        if (quotes.length) {
          const quote = find(quotes, (q) => q.status === "ESTIMATE_CREATED");
          if (quote) {
            const _quoteId = quote.id;
            setIsLoading(false);
            setWaitingForEstimates(false);
            navigate(`/concierge/${_quoteId}`);
          } else {
            const waitingForEstimatesQuote = find(
              quotes,
              (q) => q.status === "WAITING_FOR_ESTIMATES"
            );
            if (waitingForEstimatesQuote) {
              setIsLoading(false);
              setWaitingForEstimates(true);
              const _intervalId = setTimeout(() => {
                setHasFetched(false);
              }, 5000);
              setIntervalId(_intervalId);
            } else {
              navigate("/onboarding");
            }
          }
        } else {
          // setError("No quotes found for this user. Please contact support.");
          navigate("/onboarding");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, quoteId, intervalId, user]);

  useEffect(() => {
    if (!quoteId) return;
    if (hasFetched) return;
    clearInterval(intervalId as Timer);
    setHasFetched(true);
    setIsLoading(true);
    api
      .get("quote", { id: quoteId, models: "Estimate" })
      .then((response) => {
        const { quote = {} } = response;
        setQuotes([quote]);
        if (quote?.id) {
          if (quote?.Estimate && quote.Estimate.length) {
            setIsLoading(false);
            setWaitingForEstimates(false);
          } else {
            setIsLoading(false);
            setWaitingForEstimates(true);
            const _intervalId = setTimeout(() => {
              setHasFetched(false);
            }, 5000);
            setIntervalId(_intervalId);
          }
        } else {
          // setError("No quotes found for this user. Please contact support.");
          navigate("/onboarding");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
    return () => {
      if (intervalId) clearTimeout(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, quoteId, intervalId, user]);

  useEffect(() => {
    let _hasEstimate = true;
    if (quote && !waitingForEstimates && quote?.Estimate?.length) {
      let total = 0;
      quote?.Estimate?.forEach((e) => {
        total += e?.data?.installedCostTotal ?? 0;
      });
      if (total) {
        _hasEstimate = true;
      } else {
        _hasEstimate = false;
      }
      setHasEstimate(_hasEstimate);
    }
  }, [quote, waitingForEstimates]);

  useEffect(() => {
    if (quote?.channelId) {
      setChannel(quote.channelId as TenantName);
    }
  }, [quote, setChannel]);

  const iOS = getMobileOperatingSystem() === "iOS" ? true : false;
  const onboardingPayload = (quote?.Onboarding?.data ??
    {}) as Partial<OnboardingPayload>;

  return (
    <>
      {!hasEstimate && quote?.Estimate?.length ? (
        // unable to process
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: "calc(100vh - 10vh)",
            opacity: hasEstimate ? "0.0" : "1.0",
          }}
        >
          <Grid item xs={12} p={[2, 10]}>
            <Typography sx={{ fontSize: "2.0rem", fontWeight: "bold" }}>
              Sorry! We can't deliver an instant estimate.
            </Typography>
            <Typography sx={{ fontSize: "1.25rem" }} color="gray" mt={4}>
              We need to confirm a few details to properly design your system
              and create your estimate. Your Pearl Edison advsior will contact
              you.
            </Typography>
            <Typography sx={{ fontSize: "1.25rem" }} color="gray" mt={4} mb={8}>
              To connect with us sooner, use the options below.
            </Typography>
            <Stack direction="row" justifyContent="center" spacing={3}>
              <Button
                component={"a"}
                variant="contained"
                href={`tel:+${PHONE_NUMBER_TEL}`}
                style={{
                  color: theme.palette.secondary.contrastText,
                  textDecoration: "none",
                }}
              >
                Call
              </Button>
              <Button
                component={"a"}
                variant="contained"
                href={`sms:+${PHONE_NUMBER_JAKE}${
                  iOS
                    ? `&body=${encodeURIComponent(`Please contact me regarding the quote for ${onboardingPayload?.user?.formatted_address ? onboardingPayload.user.formatted_address : "my home"}`)}`
                    : `?body=${encodeURIComponent(`Please contact me regarding the quote for ${onboardingPayload?.user?.formatted_address ? onboardingPayload.user.formatted_address : "my home"}`)}`
                }`}
                style={{
                  color: theme.palette.secondary.contrastText,
                  textDecoration: "none",
                }}
              >
                Text
              </Button>
              <Button
                component={"a"}
                variant="contained"
                target="_blank"
                href={`mailto:${EMAIL}?subject=${encodeURIComponent(`Please contact me regarding the quote for ${onboardingPayload?.user?.formatted_address ? onboardingPayload.user.formatted_address : "my home"}`)}`}
                style={{
                  color: theme.palette.secondary.contrastText,
                  textDecoration: "none",
                }}
              >
                Email
              </Button>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          <RebateModal quotes={quotes} />
          {warning ? (
            <Box m={2}>
              <Alert severity="warning">{warning}</Alert>
            </Box>
          ) : null}
          {error ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "calc(100vh - 10vh)" }}
            >
              <Grid item xs={3}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            </Grid>
          ) : !user.email && !quoteId ? (
            <Navigate to="/login" />
          ) : waitingForEstimates ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "calc(100vh - 10vh)" }}
            >
              <Grid item xs={3}>
                <WaitingForEstimates />
              </Grid>
            </Grid>
          ) : isLoading || !quoteId ? (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "calc(100vh - 10vh)" }}
            >
              <Grid item xs={3}>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <QuoteContext.Provider value={{ quoteId, quote }}>
              {showCalendly ? (
                <CalendlyModal
                  handleClose={setShowCalendly}
                  setWarning={setWarning}
                />
              ) : null}
              <ConciergeView
                zoom={zoom}
                filter={filter}
                borderRadius={borderRadius}
                user={user}
                matches={matches}
                selectedTier={selectedTier}
                quote={quote}
                setSelectedEstimate={setSelectedEstimate}
                quoteId={quoteId}
                setError={setError}
                setWarning={setWarning}
                setShowCalendly={setShowCalendly}
              />
            </QuoteContext.Provider>
          )}
        </>
      )}
    </>
  );
};

export default ConciergePageView;
